import React, { Suspense, lazy } from 'react';
import ReactDOM from "react-dom/client";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";

import Loading from 'pages/loading';
const Home = lazy(() => import('pages/home'));
const ColourMatcher = lazy(() => import('pages/colour-matcher'));
const Identify = lazy(() => import('pages/identify'));
const LoginPage = lazy(() => import('pages/login'));
const Error404 = lazy(() => import('pages/error/404'));

export default function App() {
    const isAuthenticated = () => {
        const accessToken = sessionStorage.getItem("accessToken");
        return !!accessToken;
    };

    return (
        <BrowserRouter>
            <Suspense fallback={<Loading/>}>
                <Routes>
                    <Route path="/">
                        <Route index element={<Home/>}/>
                        <Route path="/login"
                            element={ isAuthenticated() ? <Navigate replace to="/" /> : <LoginPage /> }/>
                        <Route path="/colour-matcher" element={<ColourMatcher/>}/>
                        <Route path="/identify"
                               element={isAuthenticated() ? <Identify/> :
                                   <Navigate replace to="/login" state={{redirectTo: "/identify"}}/>}/>
                        <Route path="*" element={<Error404/>}/>
                    </Route>
                </Routes>
            </Suspense>
        </BrowserRouter>
    );
}

const root = ReactDOM.createRoot(document.getElementById('app')!);
root.render(<App/>);

if ('serviceWorker' in navigator) {
    window.addEventListener('load', () => {
        navigator.serviceWorker.register('/service-worker.js')
            .then(registration => {
                console.log('SW registered: ', registration);
            }).catch(registrationError => {
            console.log('SW registration failed: ', registrationError);
        });
    });
}
